export type ModalType<T = void> = {
  isOpen: boolean;
  onClose(): void;
  additionalProps: T;
};

export enum Toast {
  NONE,
  FORM_UPDATE_SUCCESS,
  FORM_UPDATE_ERROR_UNKNOWN,
  FORM_UPDATE_ERROR_INTERNAL,
  FORM_UPDATE_ERROR_UNAUTHENTICATED,
  FORM_UPDATE_ERROR_UNAVAILABLE,
  FORM_UPDATE_ERROR_PERMISSION_DENIED,
  FORM_UPDATE_ERROR_INVALID_ARGUMENT,
  FORM_UPDATE_ERROR_INVALID_URL,
  PROFILE_URL_UPDATE_SUCCESS,
  PROFILE_URL_UPDATE_ERROR,
  PROFILE_PRIVACY_CHANGE_SUCCESS,
  PROFILE_PRIVACY_CHANGE_ERROR,
}
